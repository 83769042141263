import { getEntityDataByName } from "@/data/entity/entity.data.fn";
import { ENV } from "@/lib/env";
import { create } from "zustand";
import { immer } from "zustand/middleware/immer";

export type TItemShopState = "SIGNUP" | "CONFIRM_PASS" | "BUY_GEAR";

export interface IItemShopProduct {
	name: string;
	entityTypeId: number;
	selected: boolean;
	required?: boolean;
}

export interface IItemShopStore {
	state: TItemShopState;
	playpass: IItemShopProduct;
	products: IItemShopProduct[];
	detailedProduct: IItemShopProduct | null;
	priceModifier: number;
	currency: string;
	updateProduct: (product: IItemShopProduct) => void;
}

const useItemShopStore = create<IItemShopStore>()(
	immer((set) => ({
		state: "SIGNUP",
		playpass: null!,
		products: [],
		detailedProduct: null,
		priceModifier: Number(ENV.VITE_SHOP_PRICE_MODIFIER || 1),
		currency: "ETH",
		updateProduct: (product: IItemShopProduct) =>
			set((state) => {
				const idx = state.products.findIndex((p) => p.name === product.name);
				if (idx >= 0) {
					state.products[idx] = product;
				}
			}),
	})),
);

const ItemShopStore = () => ({
	...useItemShopStore.getState(),
	set: useItemShopStore.setState,
});
export { ItemShopStore, useItemShopStore };

export const loadItemShopItems = async () => {
	const availableProducts: IItemShopProduct[] = [
		"Shovel",
		"Fertilizer",
		"Golden Shovel",
		"Dirty Glasses",
		"Pink Glasses",
		"Storm Bottle",
		"Drum Machine",
	].map((name) => {
		const e = getEntityDataByName(name);
		if (!e) throw new Error(`no entity found for ${name}`);
		return {
			name,
			entityTypeId: e.entityTypeId,
			selected: name === "Shovel" ? true: false,
		};
	});

	const playpass = (() => {
		const name = "Entry Ticket";
		const e = getEntityDataByName(name);
		if (!e) throw new Error(`No ${name}`);
		return {
			name,
			entityTypeId: e.entityTypeId,
			selected: true,
			required: true,
		};
	})();

	ItemShopStore().set({
		playpass,
		products: availableProducts,
		detailedProduct: playpass,
	});
};
