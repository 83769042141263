import { Debug } from "@/lib/debug/debug";
import {
	type MUDSetupMiddleware,
	type NetworkSetupResult,
	defaultSetup,
	getFaucet,
	getQueryParamsClient,
	setupMUD,
} from "@jungle/common/src/mud.setup";
import { ENV } from "../lib/env";
import { createSystemCalls } from "./lib/createSystemCalls";

export type SetupResult = Awaited<ReturnType<typeof setupMUD>>;
export type MUDSetup = Awaited<ReturnType<typeof initializeMUD>>;

const MUDSetupChain = [
	(config: NetworkSetupResult): NetworkSetupResult => {
		Object.assign(config.networkConfig, {
			chainId: ENV.VITE_CHAIN_ID,
			indexerServiceUrl: ENV.VITE_INDEXER,
			faucetServiceUrl: ENV.VITE_FAUCET,
		});
		return config;
	},
	getQueryParamsClient,
	...defaultSetup,
	getFaucet,
] as MUDSetupMiddleware[];

export let _network: SetupResult;
export const getNetwork = () => _network;

export let _systemCalls: ReturnType<typeof createSystemCalls>;
export const getSystemCalls = () => _systemCalls;

export let useMUDStore: SetupResult["useMUDStore"];
export let getMUDState: SetupResult["useMUDStore"]["getState"];

export let tables: SetupResult["tables"];

export async function initializeMUD() {
	const network = await setupMUD(MUDSetupChain, undefined, {
		output: Debug("MUD").log,
	});
	const systemCalls = createSystemCalls(network);
	const { playerEntity } = network;
	_network = network;
	_systemCalls = systemCalls;
	useMUDStore = network.useMUDStore;
	getMUDState = network.useMUDStore.getState;
	tables = network.tables;

	return {
		network,
		systemCalls,
		useMUDStore,
		playerEntity,
		tables,
	};
}
